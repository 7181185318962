import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/proces-sprzedazy.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    schemat: file(relativePath: { eq: "projektowanie-procesu-sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    crmquesty: file(relativePath: { eq: "zarzadzanie_szansami_sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const Processprzedazy = ({ data }) => {
  return (
    <Artykul
      title="Wsparcie procesu sprzedaży - system CRM"
      articleImage={obrazekArtykulu}
      articleImageAlt="Wsparcie procesu sprzedaży - system CRM"
      metaTitle="Wsparcie procesu sprzedaży - system CRM"
      metaDescription="Jak system CRM wspiera procesy sprzedaży? Wypróbuj bezpłatnie i zwiększ efektywność handlowców!"
    >
      <p
        style={{
          paddingTop: 20,
        }}
      >We współczesnych przedsiębiorstwach niezwykle ważną rolę odgrywają narzędzia pozwalające na 
        sprawne wsparcie procesu sprzedaży. Jego odpowiednie zaprojektowanie pomaga bowiem w&nbsp;efektywnym 
        zarządzaniu działem handlowym, osiąganiu oczekiwanych celów sprzedażowych oraz ciągłym zwiększaniu zysków firmy. 
      </p>
      <p style={{
          paddingBottom: 20,
        }}><strong>Jednym z najważniejszych rozwiązań wspierających procesy sprzedażowe są systemy CRM.</strong></p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="_zpw8pqTHqk"
          title="Efektywne zarządzanie procesem sprzedaży i zwiększanie zysków przedsiębiorstwa"
        />
      </div>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Proces sprzedaży - definicja&nbsp;i cele
      </h2>
      <p>
        <strong>Procesem sprzedażowym </strong>nazywamy taką<strong> sekwencję powtarzalnych działań</strong>, którą powinien 
        wykonać zespół handlowy - już od&nbsp;pierwszego momentu pozyskania potencjalnego klienta, aż po moment 
        zamknięcia sprzedaży. W&nbsp;zależności od rodzaju procesu sprzedażowego, może być on wspierany również 
        przez pracowników innych działów przedsiębiorstwa (np.&nbsp;grafików, projektantów lub techników), którzy 
        uzupełniają wiedzę handlowców podczas kontaktu z klientem.
      </p>
      <p>
        Odpowiednie zarządzanie działaniami handlowymi i wspieranie procesów sprzedażowych przekłada się na 
       efektywniejsze gospodarowanie zasobami oraz<strong> znaczącą poprawę produktywności </strong>i&nbsp;wyników działu sprzedaży. 
       W firmie, która nie przykłada wagi do właściwego wsparcia kluczowych procesów sprzedaży, każdy handlowiec 
       kieruje się indywidualnymi nawykami i&nbsp;schematami pracy. Niemożliwe jest w takim przypadku przeprowadzenie 
       kompleksowej analizy, która określiłaby najbardziej problematyczne dla działu handlowego etapy sprzedaży. 
       Niska koordynacja działań handlowców i brak wspólnej struktury pracy niemal uniemożliwiają skuteczną 
       optymalizację działań sprzedażowych w przedsiębiorstwie.
      </p>
      <p>
     Odpowiednie zaprojektowanie i skuteczne wsparcie procesu sprzedaży to idealny sposób na, między innymi:
      </p>
      <p>
        <ul>
          <li><strong>analizę rezultatów </strong>i zbadanie, czy określone działania na poszczególnych etapach procesu przynoszą założone wyniki,</li>
          <li><strong>diagnozę przyczyn występowania stagnacji w sprzedaży </strong>oraz ich sprawną korektę,</li>
          <li><strong>określenie i rozplanowanie poszczególnych kroków</strong> pozwalających na udane przekształcenie potencjalnego leada w&nbsp;pozyskanego klienta,</li>
          <li><strong>efektywną prognozę przyszłych wyników sprzedażowych </strong>– dzięki wiedzy dotyczącej udanej konwersji i liczby zamkniętych sprzedaży,</li>
          <li><strong>łatwiejsze wdrożenie do pracy nowego handlowca </strong>– proces sprzedażowy nie tylko określa kolejne kroki i czynności na 
          poszczególnych etapach sprzedaży, ale też wskazuje na oczekiwane wyniki i podpowiada dobre praktyki działań, które umożliwiają ich realizację.</li>
        </ul>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz wesprzeć proces sprzedaży w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        Definiowanie poszczególnych etapów procesu sprzedażowego
      </h2>
      <p>
        Przy definiowaniu i wspieraniu procesów sprzedażowych szczególną uwagę należy przykładać do ich<strong> elastyczności</strong>. 
        Sztywny proces sprzedaży, który wymaga od handlowca stałego podążania wyznaczoną drogą z pewnością nie sprawdzi 
        się w każdej sytuacji i może kosztować Twoich pracowników dodatkowe nakłady czasu. Warto więc zadbać o to, by na 
        każdym etapie procesu każdy handlowiec posiadał do pewnego stopnia wolną rękę w prowadzeniu działań sprzedażowych.
      </p>

      <p>
        <strong>Zadaniem dobrze zbudowanego procesu sprzedaży jest wspieranie pracowników działu handlowego w&nbsp;ich codziennej pracy i jedynie sugerowanie im, 
        jakie czynności (a także w jaki sposób i za pomocą jakich narzędzi) powinny zostać wykonane na poszczególnych etapach procesu.</strong>
      </p>
       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.schemat]}
        alts={["Definiowanie etapów procesu sprzedaży - schemat"]}
      />
      <p>
        Podstawowy schemat definiowania etapów procesu sprzedażowego opiera się na sześciu elementach. 
        Pierwszy z nich polega na<strong> pogrupowaniu powtarzalnych działań handlowców </strong>w kolejne kroki prowadzące do 
        finalnego zamknięcia sprzedaży. W drugim etapie należy<strong> wypisać wszystkie działania istotne w&nbsp;realizacji 
        wymienionych wcześniej kroków</strong>. Kolejny element w przedstawionym schemacie to<strong> wskazanie dostępnych dla 
        handlowców materiałów i narzędzi</strong>, z których mogą oni korzystać na przestrzeni całego procesu sprzedaży. 
      </p>
      <p>
        Kolejne trzy elementy odnoszą się do<strong> procesu zakupowego klientów Twojej firmy</strong>. Proces taki 
        przyjmuje różne formy dla różnych przedsiębiorstw, jednak zazwyczaj składają się na niego 
        pewne uniwersalne działania podejmowane przez klientów, takie jak:
      </p>
      <p><ul>
      <li>identyfikacja potrzeby i chęć zmiany</li>
      <li>poszukiwanie informacji o sposobach osiągnięcia danego celu lub rozwiązania problemu,</li>
      <li>analiza i porównywanie różnych rozwiązań i&nbsp;ocena, czy spełniają one oczekiwania,</li>
      <li>nabycie produktu lub skorzystanie z oferowanej usługi.</li>
      </ul>
      </p>
      <p>Każdy z tych etapów procesu zakupowego jest<strong> okazją dla handlowca</strong>, by rozwiać 
      wątpliwości klienta i&nbsp;odpowiedzieć na wszelkie ewentualne pytania.<strong> Uwzględnianie 
      potrzeb klienta </strong>zaczyna się już od przeanalizowania wszystkich działań, które 
      podejmuje on, by zakupić Twój produkt lub usługę. Dzięki temu jesteś w stanie 
      wspierać proces sprzedaży w taki sposób, który umożliwi Twoim handlowcom sprawne 
      przeprowadzenie klienta przez poszczególne etapy sprzedaży.
</p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        W jaki sposób rozwiązanie CRM wspiera procesy sprzedażowe?
      </h2>
      <p>
       System CRM to idealne rozwiązanie dla handlowców, które umożliwia 
       przejrzyste przedstawienie całego procesu sprzedaży za pomocą jednego 
       panelu.<strong> Gromadzi on w jednym miejscu wszystkie niezbędne informacje </strong>
       pozwalające na sprawne zamknięcie sprzedaży i&nbsp;pozyskanie klienta. Dzięki 
       temu pracownicy działu handlowego<strong> nie muszą tracić czasu </strong>na zbędne przełączanie 
       się pomiędzy różnymi systemami lub&nbsp;zakładkami i nie muszą poszukiwać informacji 
       wśród wielu wiadomości. CRM wspiera proces sprzedaży udostępniając handlowcom widok 
       pełnego procesu, który daje im<strong> możliwość łatwego i szybkiego monitorowania wszystkich 
       szans sprzedaży</strong>. Co więcej, mają oni dostęp do&nbsp;wszystkich zadań i zdarzeń 
       powiązanych z&nbsp;poszczególnymi szansami sprzedaży.
      </p>
      <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.crmquesty]}
        alts={["Przykładowe warunki w procesie automatyzacji systemu CRM"]}
      />
      <p>
        System CRM to narzędzie, które doskonale wspiera zarządzanie wieloma procesami sprzedaży, 
        niezależnie od ich specyfiki oraz indywidualnych wymagań. Proces zdobycia nowego projektu w 
        przetargu przebiega bowiem inaczej niż pozyskanie nowego klienta na podstawie leadów z działu 
        marketingu – zarówno pod względem poszczególnych etapów działania, jak i na poziomie samych 
        zadań. Jednoczesne prowadzenie w systemie wielu różnych procesów sprzedaży pozwala również na 
        ich<strong> indywidualną analizę oraz tworzenie osobnych raportów </strong>dla wybranych procesów. 
      </p>
      <p>
       <strong>Zdefiniowanie etapów procesu sprzedaży i&nbsp;jego sprawne wdrożenie w firmie to dopiero początek. </strong>
       Niezwykle istotne jest, by<strong> stale wspierać poszczególne procesy sprzedażowe </strong>w taki sposób, by pozostały 
       one aktualne i&nbsp;efektywne. Do działań, które to umożliwią należy między innymi systematyczna analiza 
       etapów procesu sprzedaży, monitorowanie działań poszczególnych handlowców oraz implementacja nowych działań usprawniających.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz wesprzeć proces sprzedaży w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>
      <p>
        Sprawdź też co piszemy na temat:
        <ul>
          <li>
            <Link to="/ofertowanie-crm-jak-zarzadzac/"> Ofertowanie w CRM: jak zarządzać tym procesem?</Link>
          </li>
          <li>
            <Link to="/cele-sprzedazowe-smart/">
              {" "}
              Cele sprzedażowe w CRM - jak je określać{" "}
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-kampaniami-crm-szanse-sprzedazy/">
              {" "}
              Zarządzanie kampaniami w CRM{" "}
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Processprzedazy;
